<template>
  <div id="support-page">
    <Breadcrumb />
    <b-row class="support-row">
      <div class="support-background">
        <p class="title text-center"> 
          {{ $t('SupportPage.Title') }} 
        </p>

        <p class="subtitle text-center">
          {{ $t('SupportPage.Subtitle') }}
        </p>
        
        <div class="d-flex flex-column flex-sm-row container-boxs-contact flex-sm-wrap">
          <div class="col-12 col-sm-6 d-flex contact-box">
            <div class="container-icon d-flex align-items-center justify-content-center">
              <span>
                <EmailIcon />
              </span>
            </div>
            <div class="container-info">
              <div class="container-title">
                <div class="info-title">
                  {{ $t('SupportPage.Email') }}
                </div>
                <div class="info-subtitle">
                  {{ $t('SupportPage.SubtitleTeamHelp') }}
                </div>
              </div>
              <div class="container-content-paste d-flex justify-content-between">
                <form action="mailto:support@troika.ind.br" method="get">
                  <button type="submit">
                    <span class="content-paste" ref="email">
                      support@troika.ind.br
                    </span>
                  </button>
                </form>
                <span class="paste-icon">
                  <div id="tooltip-copy-email">
                    <PasteIcon 
                      v-if="!emailCopied" 
                      @click="copyText('email')" 
                    />
                    <CheckIcon v-else />
                  </div>
                </span>
                <b-tooltip
                      custom-class="tooltip-support"
                      target="tooltip-copy-email"
                      triggers="hover blur"
                      positioning="top"
                      placement="top"
                >
                    <div 
                      class="info-tooltip-support" 
                      :key="`email-tooltip-${emailCopied}`"
                    >
                      {{  !emailCopied ? $t('SupportPage.CopyEmail') : $t('SupportPage.Copied') }}
                    </div>
                </b-tooltip>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 d-flex contact-box">
            <div class="container-icon d-flex align-items-center justify-content-center">
              <span>
                <WhatsappIcon />
              </span>
            </div>
            <div class="container-info">
              <div class="container-title">
                <div class="info-title">
                  WhatsApp
                </div>
                <div class="info-subtitle">
                  {{ $t('SupportPage.OperationHour') }}
                </div>
              </div>
              <div class="container-content-paste d-flex justify-content-between align-items-center">
                <form action="https://wa.me/5527996405558" method="get">
                  <button type="submit">
                    <span class="content-paste">
                      +55 27 99640-5558
                    </span>
                  </button>
                </form>
                <span class="paste-icon">
                  <div id="tooltip-copy-whatsapp">
                    <PasteIcon 
                      v-if="!whatsappCopied" 
                        @click="copyText('whatsapp')"
                    />
                    <CheckIcon v-else/>
                  </div>
                </span>
                <b-tooltip
                      custom-class="tooltip-support"
                      target="tooltip-copy-whatsapp"
                      triggers="hover blur"
                      positioning="top"
                      placement="top"
                >
                    <div class="info-tooltip-support" :key="`whatsapp-tooltip-${whatsappCopied}`">
                      {{  !whatsappCopied ? $t('SupportPage.CopyNumber') : $t('SupportPage.Copied') }}
                    </div>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span ref="whatsapp" class="number-whatsapp">
        5527996405558
      </span>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTooltip
} from 'bootstrap-vue'
import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
import imgEmailIcon from '@/assets/images/icons/email.svg'
import imgEmailSmallIcon from '@/assets/images/icons/email-small.svg'
import PasteIcon from '@/assets/images/icons/paste-icon-orange.svg'
import WhatsappIcon from '@/assets/images/icons/whatsapp-icon-white.svg'
import EmailIcon from '@/assets/images/icons/email.svg'
import CheckIcon from '@/assets/images/icons/check-circle-orange.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapMutations } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    Breadcrumb,
    imgEmailIcon,
    imgEmailSmallIcon,
    PasteIcon,
    WhatsappIcon,
    EmailIcon,
    CheckIcon,
    BTooltip,
    ToastificationContent
  },

  props: {

  },
  data() {
    return {
      windowWidth: window.innerWidth,
      emailCopied: false,
      whatsappCopied: false,
    }
  },
  mounted() {
    this.UPDATE_FLAG_SHOW_OVERLAY(false);

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    this.closeTooltip();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    copyText(type = null) {
      if(type) {
        const storage = document.createElement('textarea');
        storage.value = type === 'email' 
                          ? this.$refs.email.innerHTML
                          : type === 'whatsapp' 
                          ? this.$refs.whatsapp.innerHTML 
                          : '';
                          
        if(storage.value != ''){
          this.$refs[type].appendChild(storage);
          storage.select();
          storage.setSelectionRange(0, 99999);
          document.execCommand('copy');
          this.$refs[type].removeChild(storage);
        
          let message = type === 'email' 
            ? this.$t('SupportPage.EmailCopied') 
            : type === 'whatsapp' 
              ? this.$t('SupportPage.NumberCopied') 
              : '';
          
          let maxWidthTablet = 768;
          if(window.screen.width <= maxWidthTablet) 
            this.showToast(
              this.$t('SupportPage.Success'), 
              'SmileIcon', 
              message, 
              'success'
            );

          this.emailCopied = type === 'email' ? true : false;
          this.whatsappCopied = type === 'whatsapp' ? true : false;
          this.$root.$emit('bv::hide::tooltip');
        }
      }
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    closeTooltip(){
      window.addEventListener('scroll', ()=>{
          let tooltips = document.querySelectorAll('.tooltip');
          if(tooltips.length > 0){
            for (let tooltip of tooltips) {
                tooltip.style.display = 'none';
            }
          }
      });
    },
  }
}
</script>

<style lang="scss">

#support-page {
  .number-whatsapp {
    opacity: 0;
    position: absolute;
  }
  
  .support-row {
    min-height: 484px !important; 
    .container-boxs-contact {
      margin-top: 24px;
      align-items: center;
      justify-content: center;
      width: 100%;
      .contact-box {
        padding: 0 !important;
        margin: 12px;
        max-width: 307px;
        max-height: 130px;
        background: #4C4541;
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        .container-icon {
          width: 74px;
          height: 130px;
          border-right: 1px solid #9F8D83;
        }
  
        .container-info {
          width: 232px;
          padding: 16px 0 0 10px;
  
          .info-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
          }
  
          .info-subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
          }
        }
  
        .container-content-paste {
          margin-top: 28px;

          button {
            border: none;
            background: transparent;
            outline: none;
            padding: 0 !important;
          }

          .content-paste {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #FF8D2E;
          }
  
          .paste-icon {
            padding: 0 24px 0 10px;
            margin-top: -3px;
            user-select: none;
            svg {
              cursor: pointer;
            }
            svg:focus {
              outline: none;
            }
          }
  
          .tooltip {
            margin-bottom: 10px !important;
            .arrow {
              padding-bottom: 20px !important;
            }
          }
          .info-tooltip {
            margin-bottom: 40px !important;
          }
        }
      }
    }
  
      .support-background {
        background-image: url('../../../assets/images/icons/cubos.png');
        background-color: #362f2b;
        background-size: 175px;
        width: 100%;
        min-height: 484px !important;
        height: calc(100vh - 80px - 0.6rem - 20px);
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 58px 16px;
    }
  
    .title {
      font-weight: 500;
      font-size: 36px;
      line-height: 50px;
      color: #FFFFFF;
      margin-bottom: 0 !important;
    }
  
    .subtitle {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .support-button {
      width: 346px;
      height: 63px;
      background-color: #974900 !important;
      border-radius: 5px !important;
      border-color: #974900 !important;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .support-button:hover {
      box-shadow: none !important;
      background-color: #975416 !important;
    }
  
    .support-button:focus {
      background-color: #975416 !important;
    } 
  }

  .breadcrumb-wrapper {
    margin-bottom: 0 !important;
  }
}

.tooltip-support {
//não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0 0 12px 0;
  .tooltip-inner {
    padding: 0;
    max-width: 100%;
    min-width: 1%;
    border-radius: 6px;
    overflow: hidden;
    .info-tooltip-support {
        padding: 8px;
        max-width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
    }
  }

  .arrow {
    &::before {
      border-top-color: #323232;
      border-bottom-color: #323232;
    }
  }
}

@media(max-width: 480px) {
  #support-page {
    .support-row {
      
      .container-boxs-contact {
        .contact-box {
          margin-bottom:  24px !important;
          min-height: 110px !important;
          max-width: 288px !important;
          .container-icon {
            height: 110px !important;
          }
  
          .container-info {
            width: 219px !important;
            padding-top: 8px !important ;
            .info-title {
              font-size: 16px !important;
              line-height: 26px !important;
            }
  
            .info-subtitle {
              line-height: 16px !important;
              font-size: 12px !important;
            }
          }
  
          .container-content-paste {
            .content-paste {
              line-height: 16px !important;
            }
          }
        }
      }
  
      .title {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 1rem !important;
        width: 80%;
      }
    
      .subtitle {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        width: 80%;
        margin-bottom: 2rem !important;
      }
    
      .support-button {
        width: 234px;
        height: 38px;
        border-radius: 5px !important;
        font-size: 14px;
        line-height: 20px;
      }
    
      .email-icon-small {
        margin-right: 5px;
      }
    
      .support-background {
        background-image: url('../../../assets/images/icons/cubos.png');
        background-color: #362f2b;
        background-size: 175px;
        height: 100vh !important;
        width: 100%;
      }
    }
  }
}
</style>
  
<style scoped>
@media(min-width: 769px) {
  .toastification {
    display: none !important;
  }
}
</style>